@font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(./fonts/GothamBook.ttf) format('ttf');
}
body{
    font-family: 'Gotham', sans-serif;
    zoom: 90%;
    overflow-x: hidden;
     
}

.text-shadow-dark{
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2)
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
    article h1{
        @apply text-[#20668D] font-[500] text-3xl lg:text-5xl;
    }
    article h2{
        @apply text-[#20668D] font-[500] text-xl lg:text-4xl;
        
        /* font-size: 1.5rem; */
    }
    article h3{
        @apply text-[#20668D] font-[500] text-xl lg:text-3xl;
    }
    article h4{
        @apply text-[#20668D] font-[500] text-lg lg:text-2xl;
    }
    article h5{
        @apply text-[#20668D] font-[500] text-[16px] lg:text-xl;
    }
    article h6{
        @apply text-[#20668D] font-[500] text-[14px] lg:text-lg;
    }
    article li{
        @apply list-disc ml-4 text-[14px] md:text-[16px] lg:text-[17px];
    }
    article ol{
      @apply  ml-4 text-[14px] md:text-[16px] lg:text-[17px];
    }
    article p{
        @apply text-[#333]  list-disc py-2 text-[14px] md:text-[16px] lg:text-[17px];
    }
    article ul{
        @apply text-[#333] font-[300] my-6;
    }
    article ul li{
        @apply text-[#333] font-[300] my-3;
    }
    article ul li p{
        @apply ml-4;
    }
    article ul li div{
        @apply ml-4;
    }
    article div{
        @apply my-6;
    }
    article{
        @apply text-[#333];
    }
}


@layer base {
  .no-scroll{
      @apply h-screen overflow-hidden;
  }
  html {
    font-family: Gotham, system-ui, sans-serif;
    overflow-x: hidden;
  }
  .page-container{
      @apply  mx-[175px] p-2;
  }
  .text-body-primary{
      @apply text-[#20668D];
  }
  .text-base-orange{
      @apply text-[#F26921];
  }
  .text-base-gray{
      @apply text-[#888];
  }
  .bg-base-orange{
      @apply bg-[#F26921] text-white;
  }
  .bg-base-danger{
      @apply bg-[#FF5858] text-white;
  }
  .bg-base-primary{
      @apply bg-[#2A5985] text-white;
  }
  .bg-blue-light{
      @apply bg-[#E9F5FC];
  }
}


  .page-link.active, .active > .page-link{
    background-color :#16319E;
  }

  .nav-pills{
    border-bottom: 1px solid rgb(230, 230, 255);
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    color: #000099;
    background-color: rgb(230, 230, 255);
   
  }

  .nav-pills .nav-link:hover, .nav-pills .show > .nav-link{
    color: #000099;
    background-color: rgb(240, 240, 240);
    border-bottom: 2px solid rgb(240, 240, 240);
  }
  
  .nav-pills .nav-link{
    border-radius: 0px;
  }
  .nav-link{
    color : #696969
  }
  .tab-category{
    padding-left: 10px;
    padding-right: 10px;
  }
  .catalog .card{
    background-color: #004c95 !important;
    border-radius: 0px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 4px solid #ee7442;
    /* width: 361.98px; */
  }

  .catalog .produk-lain .card{
    background-color: #ffff !important;
    border-radius: 0px;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 4px solid #ee7442;
    /* width: 361.98px; */
  }

  
  /* .category .nav-pills{
    border-bottom: 1px solid rgb(230, 230, 255);
    /* border-top: 1px solid rgb(230, 230, 255); */
  

  .border-table-ver{
    /* border-left: 1px solid rgb(230, 230, 255);
    height: 50%; */
    border-left: 1px solid rgb(230, 230, 255);
    border-right: 1px solid rgb(230, 230, 255);
  border-image: linear-gradient(to top, rgb(230, 230, 255) 100%, rgba(0,0,0,0) 50%); /* to top - at 50% transparent */
  border-image-slice: 1;
  
  }
  .category .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    color: #000099;
    background-color: transparent;
    border-top: 4px solid #ee7442;
    border-right: 1px solid rgb(240, 240, 240);
    border-left: 1px solid rgb(240, 240, 240);
  }

  .category .nav-pills .nav-link:hover, .nav-pills .show > .nav-link{
    color: #000099;
    background-color: rgb(240, 240, 240);
    
  }

  .category .nav-pills .nav-link:hover, .nav-pills .show > .nav-link{
    color: #000099;
    background-color: rgb(240, 240, 240);
    border-bottom: 2px solid rgb(240, 240, 240);
  }
  
  .category .nav-pills .nav-link{
    border-radius: 0px;
  }
  .category .nav-link{
    color : #696969
  }

  /* .t
  ab-border{
    border-left: 1px solid rgb(240, 240, 240);
   
        top: 50%;
        bottom: 0;
  } */

  .produk-img{
    width: auto;
    height: 200px;
  }

  .detail-produk-img{
    width: 361.98px;
    height: 250px;
    object-fit: cover;
  }


#product-name{
    position: relative;
    bottom: 40px;
    border-radius: 0px;
    justify-content: left;
    padding: 10px;
    background-color: #ee7442 !important;
    color: #ffff !important;
}
#mount{
    position: relative;
    bottom: 215px;
    border-radius: 0px;
    justify-content: left;
    /* padding: 10px; */
    background-color: #ee7442 !important;
    color: #ffff !important;
}

.content .btn{
    border-left: 1px solid rgb(197, 197, 197);
    color: #008dbd !important;
}
.content .btn-group{
    color: #000099 !important;
 
}

.content #icon-search {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ffff;
    border: 1px solid #ced4da;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;
}
.content .form-control {
    border-left: none;
}
.content #search-button{
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ffff;
    border: 1px solid #ced4da;
    /* border-l: none; */
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
}
.table-anggota .table{
  border-top: 4px solid #ee7442;
  color: #004c95;
}

.table-anggota .table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #ddd;
  color: #004c95;
}
.table-anggota  th{
  font-weight: 300px !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color : #616161 !important;
  color: #ffff;
}
.Bulletin .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon, 
.catalog .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon,
.category .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon
{
  display: none !important;
}

.Bulletin .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected, 
.catalog .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.category .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected
{
  background-color : #004c95 !important;
  color: #ffff;
}
.img-list-bulletin{
  width: 233px;
  height: 330px;
}
.title-gallery{
  font-style: normal;
font-weight: 500;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.desc-gallery{
  font-style: normal;
font-weight: 400;
 /* display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
}
.download-app{
  width: 164.31px;
height: 48.61px;
}
/* .list-foo li{
  width: 150px;
}  */

.img-cat-list{
  height: 156px;
  object-fit: cover;
}

.mui .swiper{
  height: 200px;
}

ol li {
  list-style: decimal;
 
} 
ol li {
  list-style: decimal;
 
}

ol ol li {
  list-style-type: lower-alpha;
  /* list-style-position: inside; */
}

.header-shadow{
  box-shadow: -3px 67px 5px 0px rgba(204,198,198,0.25);
  -webkit-box-shadow: -3px 67px 5px 0px rgba(204,198,198,0.25);
  -moz-box-shadow: -3px 67px 5px 0px rgba(204,198,198,0.25);
}
 
.produk .swiper-pagination-bullets, .produk .swiper-pagination-bullets.swiper-pagination-horizontal{
  height: 3rem;
  background-color: rgba(0,0,0,0.3);

  padding-left: 500px;
  padding-right: 500px;
}

.produk .swiper{
  max-width: 1000 !important;
}

.swiper-button-next, .swiper-button-prev{
  color: #004c95;
}
.produk-lain-swipper .swiper .swiper-wrapper{
  
    max-width: 600px !important;

}



.swiper-pagination-bullet{
  margin-top: 15px !important;
  background: #ffff;
  border: 1px solid #ffff;
  width: 15px;
  height: 15px;
}
.dedication-col{
  margin-left: 190px;

}

.col-footer{
  display: flex;
  flex-direction: row;
}

.catalog-card-col{
  width: 361.98px;
}

.list-button {
  display: grid;
  grid-template-columns: repeat(27, 1fr);
  
}

@media (max-width: 1023px) {
 
    .page-container{
        @apply  mx-[100px] p-2;
    }
  
}
@media (max-width: 1400px) {
  .list-button {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    
  }
}
@media (max-width: 1200px) {
  .list-button {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    
  }
}
@media (max-width: 992px) {
  .list-button {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    
  }
  .dedication-col{
    margin-left: 100px;
  
  }
  .col-footer{
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
 
    .page-container{
        @apply  mx-[20px] p-2;
    }
    .dedication-col{
      margin-left: 50px;
    
    }
    .catalog-card-col{
      width: auto;
    }
    .list-button {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      
    }
  
}
@media (max-width: 576px) {
  .list-button {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    
  }
  .dedication-col{
    margin-left: 30px;
  
  }
}

/* ADD MODAL */
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

/*
default style for popup

*/
.popup-content {

  margin: auto;
  background: rgb(255, 255, 255);
  width: 80%;
  padding: 5px;
  border: 1px solid #d7d7d7;
  max-height: 100vh;
  overflow:scroll;
  overflow-x:hidden;
  left: 0;
  top: 0;
  /* position: absolute; */
  z-index: 50;
  
}

#scroll-content {
  overflow:scroll;
  overflow-x:hidden;
  max-height: 100vh;

}

.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  border-radius: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-arrow {
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  /* bottom: 0; */
}


.btn-faq {
    display: flex !important;
    flex-direction: row;
    color: #fff !important;
    justify-content: space-between;
    margin-bottom: 5px;
    background-color: #032C6C !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
}

.faq h2 {
  font-size: 1.5rem !important;

}

.MuiSvgIcon-root {
  color: white;
}

.MuiButtonBase-root{
  margin-bottom: 0px !important;
}


a.btn-faq::after {
  font-family: FontAwesome;
  content: "\f105";
}

#askMe {
    position: fixed;
    bottom: .5rem;
    right: .5rem;
    display: flex;
    flex-direction: column;
    z-index: 1050;
}
   

.btn-ask {
  background-color: #002C6B !important;
  border-radius: 30px;
  margin: 5px;
  padding: 20px;  
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.box-ask {
  background-color: #EBEBEB;
  border-radius: 10px;
  max-width: 300px;
  /* width: 15%;
  height: 60%; */
  /* height: 55%; */
  padding: 10px;
}

.askHeader {
  background-color: #002C6B !important;
  color: white;
  margin: -7px;
  padding: 2px;
  margin-bottom: 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.askContent {
  margin: 10px;
}

.submit-ask {
  width: 100%;
}



